import { useTranslation } from 'react-i18next'

import dancewiczLight from './logos/dancewiczLight.png'
import dancewiczDark from './logos/dancewiczDark.png'
import laminaLight from './logos/laminaLight.png'
import laminaDark from './logos/laminaDark.png'
import jcLight from './logos/jcLight.png'
import jcDark from './logos/jcDark.png'
import nicechemDark from './logos/nicechemDark.png'
import nicechemLight from './logos/nicechemLight.png'
import betterbeLight from './logos/betterbeLight.png'
import betterbeDark from './logos/betterbeDark.png'
import mgDark from './logos/mgDark.png'
import mgLight from './logos/mgLight.png'

import classes from './Clients.module.css'
import Title from '../UI/Title'

const Clients = ({ onTheme }) => {
	const { t } = useTranslation()
	let theme = onTheme

	return (
		<section className={classes.clients}>
			<Title border={true}>{t('clients')}</Title>
			<div className={classes.clients__wrapper}>
				<div className={classes.clients__logos}>
					<div className={classes.clients__item}>
						<img src={theme === 'dark' ? dancewiczLight : dancewiczDark} alt="" />
					</div>
					<div className={classes.clients__item}>
						<img src={theme === 'dark' ? mgLight : mgDark} alt="" />
					</div>

					<div className={classes.clients__item}>
						<img src={theme === 'dark' ? jcLight : jcDark} alt="" />
					</div>

					<div className={classes.clients__item}>
						<img src={theme === 'dark' ? laminaLight : laminaDark} alt="" />
					</div>

					<div className={classes.clients__item}>
						<span>in progress</span>
						<img src={theme === 'dark' ? nicechemLight : nicechemDark} alt="" />
					</div>
					<div className={classes.clients__item}>
						<span>refactoring</span>
						<img src={theme === 'dark' ? betterbeLight : betterbeDark} alt="" />
					</div>

					<div className={classes.clients__item}>
						<img src={theme === 'dark' ? dancewiczLight : dancewiczDark} alt="" />
					</div>
					<div className={classes.clients__item}>
						<img src={theme === 'dark' ? mgLight : mgDark} alt="" />
					</div>

					<div className={classes.clients__item}>
						<img src={theme === 'dark' ? jcLight : jcDark} alt="" />
					</div>

					<div className={classes.clients__item}>
						<img src={theme === 'dark' ? laminaLight : laminaDark} alt="" />
					</div>

					<div className={classes.clients__item}>
						<span>in progress</span>
						<img src={theme === 'dark' ? nicechemLight : nicechemDark} alt="" />
					</div>
					<div className={classes.clients__item}>
						<span>refactoring</span>
						<img src={theme === 'dark' ? betterbeLight : betterbeDark} alt="" />
					</div>
				</div>
			</div>
		</section>
	)
}

export default Clients
