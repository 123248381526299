import dancewiczPreview from './images/dancewicz/dancewiczPreview.webp'
import dancewiczDesktop from './images/dancewicz/dancewiczDesktop.webp'
import dancewiczTablets from './images/dancewicz/dancewiczTablets.webp'
import dancewiczPhones from './images/dancewicz/dancewiczPhones.webp'

import laminaPreview from './images/lamina/laminaPreview.webp'
import laminaDesktop from './images/lamina/laminaDesktop.webp'
import laminaTablets from './images/lamina/laminaTablets.webp'
import laminaPhones from './images/lamina/laminaPhones.webp'

import jcPreview from './images/jc/jcPreview.webp'
import jcDesktop from './images/jc/jcDesktop.webp'
import jcTablets from './images/jc/jcTablets.webp'
import jcPhones from './images/jc/jcPhones.webp'

import mgrekPreview from './images/mgrek/mgrekPreview.webp'
import mgrekDesktop from './images/mgrek/mgrekDesktop.webp'
import mgrekTablets from './images/mgrek/mgrekTablets.webp'
import mgrekPhones from './images/mgrek/mgrekPhones.webp'

import nicechemPreview from './images/nicechem/nicechemPreview.webp'
import nicechemDesktop from './images/nicechem/nicechemDesktop.webp'
import nicechemTablets from './images/nicechem/nicechemTablets.webp'
import nicechemPhones from './images/nicechem/nicechemPhones.webp'

import betterbePreview from './images/betterbe/betterbePreview.webp'
import betterbeDesktop from './images/betterbe/betterbeDesktop.webp'
import betterbeTablets from './images/betterbe/betterbeTablets.webp'
import betterbePhones from './images/betterbe/betterbePhones.webp'

import fishing from './images/applications/fishing.png'

export const websites = [
	{
		path: '/prawnik-dancewicz',
		id: 'prawnik-dancewicz',
		type: 'Website',
		used: 'HTML5, CSS3, SASS, JavaScript, WordPress',
		name: 'prawnik-dancewicz.pl',
		imagePreview: dancewiczPreview,
		imageDesktop: dancewiczDesktop,
		imageTablets: dancewiczTablets,
		imagePhones: dancewiczPhones,
		descriptionTop:
			'In this project, I successfully developed a website tailored for a lawyer, employing a range of cutting-edge technologies such as HTML5, CSS3, SASS, and JavaScript. This ensured not only a well-structured and visually appealing website but also a highly interactive user experience.',
		descriptionBottom:
			"Furthermore, I implemented responsive web design principles to ensure the website's accessibility and functionality across various devices and screen sizes. To empower the lawyer with easy content management, I integrated WordPress into the website. Additionally, I designed a complementary business card, maintaining a consistent and professional brand identity both online and offline.",
		galleryButtons: ['desktops', 'tablets', 'phones', 'business card'],
	},
	{
		path: '/lamina-gliwice',
		id: 'lamina-gliwice',
		type: 'Website',
		used: 'Bootstrap, PHP Mailer',
		name: 'lamina-gliwice.pl',
		imagePreview: laminaPreview,
		imageDesktop: laminaDesktop,
		imageTablets: laminaTablets,
		imagePhones: laminaPhones,
		descriptionTop:
			'In my website project, I utilized Bootstrap and PHP Mailer. Bootstrap served as a tool for rapidly constructing a responsive user interface by providing pre-made components like navigation menus and forms. This enabled me to create a visually appealing and efficiently functioning website that adapted seamlessly to various devices.',
		descriptionBottom:
			"Additionally, PHP Mailer was employed to handle the contact form on the site. This tool facilitated secure email communication from the website to designated recipients and ensured the effective processing of user-entered data. The combination of Bootstrap and PHP Mailer allowed for the creation of a professional website that met the client's expectations and provided users with a user-friendly experience.",
		galleryButtons: ['desktops', 'tablets', 'phones'],
	},
	{
		path: '/jc-kancelaria',
		id: 'jc-kancelaria',
		type: 'Website',
		used: 'HTML5, CSS3, SASS, JavaScript, WordPress',
		name: 'jc-kancelaria.pl',
		imagePreview: jcPreview,
		imageDesktop: jcDesktop,
		imageTablets: jcTablets,
		imagePhones: jcPhones,
		descriptionTop:
			'In my website project, I employed HTML5, CSS3, and JavaScript to create an attractive and interactive website. HTML5 provided a solid structure, CSS3 delivered appealing styles, and JavaScript added dynamic features. Additionally, I utilized WordPress for easy content management, simplifying content updates. Organizing a professional photoshoot contributed high-quality visuals.',
		descriptionBottom:
			'Based on the entire project, I also designed a business card, aiding in brand promotion and identity building. This project harmonized various aspects of website development, visual content creation, and marketing strategy, delivering a comprehensive solution for the client.',
		galleryButtons: ['desktops', 'tablets', 'phones', 'business card'],
	},
	{
		path: '/mgrek',
		id: 'mgrek',
		type: 'Website',
		used: 'React (i18next, Router, EmailJS, Framer Motion), Firebase',
		name: 'mgrek.com',
		imagePreview: mgrekPreview,
		imageDesktop: mgrekDesktop,
		imageTablets: mgrekTablets,
		imagePhones: mgrekPhones,
		descriptionTop:
			"My portfolio project is still a work in progress, but I'm already proud of it. I built it using tools like React, i18next for multi-language support, router, Framer Motion, React Slick Slider, React-Use-Scroll, and I host it on Firebase. The project is available online and serves not only as a showcase of my skills but also as a platform for experimenting and honing my programming abilities.",
		descriptionBottom:
			"I'm actively working on further improvements and expanding its functionality. Being open to user suggestions, I appreciate any feedback that can help make my portfolio even more appealing and effective in presenting my work and projects.",
		galleryButtons: ['desktops', 'tablets', 'phones'],
	},
	{
		warning: 'refactoring',
		path: '/betterbe',
		id: 'betterbe',
		type: 'Website',
		used: 'React, React Router, Framer Motion',
		name: 'bettebe.pl',
		imagePreview: betterbePreview,
		imageDesktop: betterbeDesktop,
		imageTablets: betterbeTablets,
		imagePhones: betterbePhones,
		descriptionTop:
			'I am currently working on refactoring a website project, which will be modernized using technologies such as React, React Router, and Framer Motion. The current website requires improvements in terms of performance, responsiveness, and interactivity, and these new technologies will enable us to deliver on those fronts.',
		descriptionBottom:
			'The refactoring also includes optimizing the appearance and layout of the website on mobile devices, ensuring excellent user experiences across various platforms. My goal is to create a modern and competitive website that meets user expectations and functions smoothly on both desktop and mobile devices.',
		galleryButtons: ['desktops', 'tablets', 'phones'],
	},
	{
		warning: 'in progress',
		path: '/nicechem',
		id: 'nicechem',
		type: 'Website',
		used: 'HTML5, CSS3, SASS, JavaScript, WordPress, WooCommerce',
		name: 'nicechem.pl',
		imagePreview: nicechemPreview,
		imageDesktop: nicechemDesktop,
		imageTablets: nicechemTablets,
		imagePhones: nicechemPhones,
		descriptionTop:
			"The website project I'm currently working on is still in progress and not in its final form. I've utilized a range of technologies, including HTML5, CSS3, Sass, and JavaScript, to create an appealing and responsive user interface. These technologies have provided a robust structure, aesthetic styles, and dynamic functionalities for the website.",
		descriptionBottom:
			"Furthermore, I've implemented WordPress along with WooCommerce to enable the addition of products to the portfolio. This combination allows the client to conveniently manage their content and conduct online sales. The project is continuously evolving and being improved to meet the client's expectations and provide users with the best possible experience.",
		galleryButtons: ['desktops', 'tablets', 'phones'],
	},
]

export const applications = [
	{
		warning: 'not started',
		path: '/fishing-app',
		id: 'fishing-app',
		type: 'Application',
		used: 'React',
		name: 'fishing app',
		imagePreview: fishing,
		imageDesktop: fishing,
		descriptionTop:
			'In the future, there is a planned application aimed at individuals organizing fishing tournaments. It will be an innovative tool designed to simplify the planning, coordination, and management of such events. With this application, organizers will easily create fishing events, specify dates and locations, and precisely tailor regulations to their needs.',
		descriptionBottom:
			'The application will also facilitate the collection of entry fees, manage participant lists, and track results in real-time. Integrated communication tools will allow organizers to stay in touch with participants and provide them with important information. Additionally, the application will offer support in promoting tournaments, ensuring a broader reach and increased event visibility. This tool aims to revolutionize the process of organizing fishing tournaments, providing convenience and efficiency for all involved.',
		galleryButtons: ['desktops'],
	},
]
