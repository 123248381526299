import classes from './AboutSlider.module.css'

const AboutSlider = ({ onCurrentSlide, sliderGallery }) => {
	return (
		<div className={classes.about__slider}>
			{sliderGallery.map(item => (
				<div
					className={`${classes.about__image} ${
						item.id === onCurrentSlide ? classes.about__show : classes.about__hide
					}`}
					key={item.id}>
					{item.id === onCurrentSlide && <img src={item.image} alt={item.description} />}
				</div>
			))}
		</div>
	)
}

export default AboutSlider
