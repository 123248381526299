import classes from './Title.module.css'

const Title = props => {
	return (
		<div className={props.border ? classes.title__border : undefined}>
			<div className={classes.title__content}>
				<h2>{props.children}</h2>
			</div>
		</div>
	)
}

export default Title
