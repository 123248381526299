import { useTranslation } from 'react-i18next'
import classes from './Home.module.css'

const Home = () => {
	const { t } = useTranslation()

	return (
		<section className={`${classes.home} home`}>
			<div className={classes.home__content}>
				<h1>
					mateusz
					<br />
					grek.
				</h1>
				<div className={classes.home__text}>
					{t('home_i_am')} <span>{t('home_front_end_developer')}.</span>
					<br />
					{t('home_from_silesia')}.
				</div>
			</div>
		</section>
	)
}

export default Home
