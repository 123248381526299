import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import classes from './AboutSkillsType.module.css'

const AboutSkillsType = ({ content, onActiveButtons }) => {
	const [activeIndex, setActiveIndex] = useState()
	const [activeSkill, setActiveSkill] = useState(0)

	const showSkillItemHandler = item => {
		if (activeIndex === item) {
			return setActiveIndex(null)
		}
		setActiveIndex(item)
		setActiveSkill(0)
	}

	const showActiveSkill = index => {
		if (activeSkill === index) {
			return setActiveSkill(0)
		}
		setActiveSkill(index)
	}

	useEffect(() => {
		if (onActiveButtons.showCode === true) {
			setActiveIndex('c1')
		} else {
			setActiveIndex('x1')
		}
	}, [onActiveButtons.showCode, onActiveButtons.showExperience])

	return (
		<>
			{content.map(item => (
				<div className={`${classes['skills__type--item']} `} key={item.id}>
					<div className={classes['skills__type--top']} onClick={() => showSkillItemHandler(item.id)}>
						<div
							className={`${classes['skills__type--title']} ${
								activeIndex === item.id && classes['skills__type--active']
							}`}>
							<h4>{item.title}</h4>
						</div>

						<div
							className={`${classes['skills__type--icon']} ${
								activeIndex === item.id && classes['skills__type--active']
							}`}>
							<motion.i
								animate={{ rotate: activeIndex === item.id ? 180 : 0 }}
								className={`${'lni lni-arrow-down'} `}></motion.i>
						</div>
					</div>

					<div
						className={`${classes['skills__type--description']} ${
							activeIndex === item.id && classes['skills__type--show']
						}`}>
						<div className={`${classes['skills__type--skills']}`}>
							{item.subtitle.map((item, index) => {
								return (
									<h6
										className={`${activeSkill === index && classes['skills__type--highlight']}`}
										key={index}
										onClick={() => showActiveSkill(index)}>
										{item}
									</h6>
								)
							})}
						</div>
						<div>
							{item.description.map((item, index) => {
								return activeSkill === index && <p key={index}>{item}</p>
							})}
						</div>
					</div>
				</div>
			))}
		</>
	)
}

export default AboutSkillsType
