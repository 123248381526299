import { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AnimatePresence } from 'framer-motion'
import emailjs from '@emailjs/browser'

import classes from './Footer.module.css'
import Title from '../UI/Title'
import Button from '../UI/Button'
import FooterPrivacy from './FooterPrivacy'

const Footer = () => {
	const [showPrivacy, setShowPrivacy] = useState(false)
	const { t } = useTranslation()

	const showPrivacyHandler = () => {
		setShowPrivacy(!showPrivacy)
	}

	const form = useRef()

	const [name, setName] = useState('')
	const [mail, setMail] = useState('')
	const [subject, setSubject] = useState('')
	const [message, setMessage] = useState('')
	const [warning, setWarning] = useState('')

	const sendEmail = e => {
		e.preventDefault()

		emailjs
			.sendForm(
				process.env.REACT_APP_SERVICE_ID,
				process.env.REACT_APP_TEMPLATE_ID,
				form.current,
				process.env.REACT_APP_PUBLIC_KEY
			)
			.then(
				result => {
					setWarning('Message has been sent!')
				},
				error => {
					setWarning('Something went wrong, please contact via phone.')
				}
			)

		setName('')
		setMail('')
		setSubject('')
		setMessage('')
	}
	return (
		<>
			<footer className={`${classes.footer} contact`}>
				<Title border={true}>{t('get_in_touch')}</Title>
				<div className={classes.footer__map}>
					<iframe
						title="map"
						height="350"
						frameBorder="0"
						scrolling="no"
						seamless="seamless"
						src="https://www.openstreetmap.org/export/embed.html?bbox=18.620967864990238%2C50.27496948420653%2C18.69306564331055%2C50.31696966407885&amp;layer=mapnik"></iframe>
				</div>

				<div className={classes.footer__wrapper}>
					<div className={classes.footer__form}>
						<div className={classes['footer__form--title']}>
							<h3>{t('message_me')}</h3>
						</div>
						<form ref={form} onSubmit={sendEmail}>
							<input
								type="text"
								placeholder="Name"
								id="user_name"
								name="user_name"
								value={name}
								onChange={event => setName(event.target.value)}
							/>
							<input
								type="email"
								placeholder="Email"
								id="user_email"
								name="user_email"
								value={mail}
								onChange={event => setMail(event.target.value)}
							/>
							<input
								type="text"
								placeholder="Subject"
								id="user_subject"
								name="user_subject"
								value={subject}
								onChange={event => setSubject(event.target.value)}
							/>
							<textarea
								cols="30"
								rows="5"
								placeholder="Message"
								id="user_message"
								name="user_message"
								value={message}
								onChange={event => setMessage(event.target.value)}></textarea>
							{/* <label>
								<input type="checkbox"></input>I consent to the storage of my data in accordance with the guidelines
								outlined in the <span onClick={showPrivacyHandler}>Privacy Policy</span>.
							</label> */}
							<div className={classes.footer__warning}>{warning}</div>
							<Button type={'submit'}>
								{t('send_mail')} <i className="lni lni-envelope"></i>
							</Button>
						</form>
					</div>

					<div className={classes.footer__box}>
						<div className={classes.footer__details}>
							<div className={classes['footer__details--title']}>
								<h3>{t('my_details')}</h3>
							</div>
							<p>
								If you are interested in my services or would like to establish a longer collaboration, I encourage you
								to use any available form of contact. I am ready to answer any questions and discuss the details of our
								cooperation.
							</p>
						</div>
						<div className={classes.footer__contact}>
							<ul>
								<li>
									<div className={classes['footer__contact--icon']}>
										<i className="lni lni-envelope"></i>
									</div>

									<span>mateusz.grek@icloud.com</span>
								</li>
								<li>
									<span className={classes['footer__contact--icon']}>
										<i className="lni lni-phone-set"></i>
									</span>
									<span>+48 732-989-789</span>
								</li>
								<li>
									<span className={classes['footer__contact--icon']}>
										<i className="lni lni-map-marker"></i>
									</span>
									<span>Silesia, Poland</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<AnimatePresence>{showPrivacy && <FooterPrivacy onShow={showPrivacy} />}</AnimatePresence>
				<div className={classes.footer__copyrights}>
					<p>
						© 2023 Mateusz Grek by <a href="www.google.com">Me</a>. All Rights Reserved.
					</p>
				</div>
				<div className={classes.footer__socials}>
					<ul>
						<li>
							<a href="https://github.com/mateusz-grek">
								<i className="lni lni-github"></i>
							</a>
						</li>
						<li>
							<a href="https://www.linkedin.com/in/mateusz-grek-68a634223/">
								<i className="lni lni-linkedin"></i>
							</a>
						</li>
						<li>
							<a href="https://www.facebook.com/profile.php?id=100068921553925">
								<i className="lni lni-facebook"></i>
							</a>
						</li>
						<li>
							<a href="https://www.instagram.com/b0gusz/">
								<i className="lni lni-instagram"></i>
							</a>
						</li>
					</ul>
				</div>
			</footer>
		</>
	)
}

export default Footer
