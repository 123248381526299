import { Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { motion } from 'framer-motion'

import { Link } from 'react-router-dom'
import classes from './WorksItems.module.css'

const WorksItems = ({ array, show, currentItem }) => {
	return (
		<motion.div
			variants={{
				hidden: { opacity: 0 },
				visible: { opacity: 1 },
			}}
			initial="hidden"
			animate="visible"
			exit="hidden">
			<motion.div
				variants={{
					visible: { transition: { staggerChildren: 0.2 } },
				}}
				className={classes.portfolio__wrapper}>
				{array?.map(item => (
					<motion.div
						variants={{
							hidden: { opacity: 0 },
							visible: { opacity: 1 },
						}}
						exit={{ opacity: 1 }}
						transition={{ type: 'spring' }}
						className={classes.portfolio__item}
						key={item.id}
						onClick={() => currentItem(item)}>
						<Link
							to={`/${item.id}`}
							onClick={(() => show(false), () => scroller.scrollTo('works', { smooth: true, offset: 50 }))}>
							<div className={classes.portfolio__overlay}>
								{item.warning && (
									<>
										<div className={classes['portfolio__warning--border']}></div>
										<div className={classes.portfolio__warning}>
											<span>{item.warning}</span>
										</div>
									</>
								)}
								<div className={classes['portfolio__overlay--text']}>
									<span>{item.name}</span>
								</div>
							</div>
							<img src={item.imagePreview} alt={item.name} />
						</Link>
					</motion.div>
				))}
			</motion.div>
		</motion.div>
	)
}

export default WorksItems
