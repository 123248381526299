import { useTranslation } from 'react-i18next'
import Title from '../UI/Title'

import classes from './Charity.module.css'
import image from './charity.webp'

const Charity = () => {
	const { t } = useTranslation()
	return (
		<>
			<section className={`${classes.charity} charity`}>
				<Title border={true}>{t('charity')}</Title>

				<div className={classes.charity__wrapper}>
					<div style={{ backgroundImage: `url(${image})` }} className={classes.charity__image}></div>
					<div className={classes.charity__content}>
						<h3>{t('about')}</h3>
						<p>{t('charity_about')}</p>
						<h3>{t('how_it_works')}</h3>
						<p>{t('charity_how_it_works')}</p>
					</div>
				</div>
			</section>
		</>
	)
}

export default Charity
