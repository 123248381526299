import { motion } from 'framer-motion'
import classes from './FooterPrivacy.module.css'

const FooterPrivacy = ({ onShow }) => {
	console.log(onShow)
	return (
		<>
			<motion.div
				initial={{ opacity: 0, y: -30 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: -30 }}
				className={classes.privacy}>
				<div className={classes.privacy__content}>
					<h2>Polityka Prywatności</h2>
					<h3>1. Definicje</h3>
					<ul>
						<li>
							1.1. Administrator – Kancelaria Radcy Prawnego Wojciech Dancewicz, ul. Szpitalna 8/409, 44-190 Knurów,
							NIP: 6312534424
						</li>
						<li>
							1.2. Dane osobowe – informacje o osobie fizycznej zidentyfikowanej lub możliwej do zidentyfikowania
							poprzez jeden bądź kilka szczególnych czynników określających fizyczną, fizjologiczną, genetyczną,
							psychiczną, ekonomiczną, kulturową lub społeczną tożsamość, w tym IP urządzenia, identyfikator internetowy
							oraz informacje gromadzone za pośrednictwem plików cookie oraz innej podobnej technologii.
						</li>
						<li>1.3. Polityka – niniejsza Polityka prywatności.</li>
						<li>
							1.4. RODO – rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.04.2016 r. w sprawie ochrony
							osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych
							oraz uchylenia dyrektywy 95/46/WE.
						</li>
						<li>
							1.5. Serwis – serwis internetowy prowadzony przez Administratora pod adresem www.prawnik-dancewicz.pl
						</li>
						<li>
							1.6. Użytkownik – każda osoba fizyczna odwiedzająca Serwis lub korzystająca z jednej albo kilku usług czy
							funkcjonalności opisanych w Polityce.
						</li>
					</ul>
					<h3>2. Przetwarzanie Danych osobowych w związku z korzystaniem z Serwisu</h3>
					<ul>
						<li>
							2.1 W związku z korzystaniem przez Użytkownika z Serwisu Administrator zbiera dane w zakresie niezbędnym
							do świadczenia poszczególnych oferowanych usług. Poniżej zostały opisane szczegółowe zasady oraz cele
							przetwarzania Danych osobowych gromadzonych podczas korzystania z Serwisu przez Użytkownika.
						</li>
					</ul>
					<h3>3. Cele i podstawy prawne przetwarzania Danych osobowych w Serwisie</h3>
					A) Korzystanie z Serwisu 3.1. Dane osobowe wszystkich osób korzystających z Serwisu przetwarzane są przez
					Administratora: 3.1.1. w celu świadczenia usług drogą elektroniczną w zakresie udostępniania Użytkownikom
					treści gromadzonych w Serwisie – wówczas podstawą prawną przetwarzania jest niezbędność przetwarzania do
					wykonania umowy (art. 6 ust. 1 lit. b RODO); 3.1.2. w celu ustalenia i dochodzenia roszczeń lub obrony przed
					roszczeniami – podstawą prawną przetwarzania jest prawnie uzasadniony interes Administratora (art. 6 ust. 1
					lit. f RODO), polegający na ochronie jego praw. B) Formularz kontaktowy 3.2. Administrator zapewnia możliwość
					skontaktowania się z nim przy wykorzystaniu elektronicznego formularza kontaktowego. Skorzystanie z formularza
					wymaga podania Danych osobowych niezbędnych do nawiązania kontaktu z Użytkownikiem i udzielenia odpowiedzi na
					zapytanie. Użytkownik może podać także inne dane w celu ułatwienia kontaktu lub obsługi zapytania. Podanie
					danych oznaczonych jako obowiązkowe jest wymagane w celu przyjęcia i obsługi zapytania, a ich niepodanie
					skutkuje brakiem możliwości obsługi. Podanie pozostałych danych jest dobrowolne. 3.3. Dane osobowe są
					przetwarzane w celu identyfikacji nadawcy oraz obsługi jego zapytania przesłanego przez udostępniony formularz
					– podstawą prawną przetwarzania jest niezbędność przetwarzania do wykonania umowy o świadczenie usługi (art. 6
					ust. 1 lit. b RODO); w zakresie danych podanych fakultatywnie podstawą prawną przetwarzania jest zgoda (art. 6
					ust. 1 lit. a RODO). 3.4. Dane osobowe są przetwarzane: 3.4.1. w celu przesłania zamówionej informacji
					handlowej – podstawą prawną przetwarzania jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit.
					f RODO) w związku z wyrażoną zgodą; 3.4.2. w celach analitycznych i statystycznych – podstawą prawną
					przetwarzania jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO), polegający na
					prowadzeniu analiz aktywności Użytkowników w Serwisie w celu doskonalenia stosowanych funkcjonalności. 4.
					Polityka cookie 4.1 Administrator w ramach Serwisu korzysta z plików cookie. Pliki cookies to małe pliki
					tekstowe instalowane na urządzeniu Użytkownika przeglądającego Serwis. Cookies zbierają informacje ułatwiające
					korzystanie ze strony internetowej – np. poprzez zapamiętywanie odwiedzin Użytkownika w Serwisie i
					dokonywanych przez niego czynności. 4.2 Administrator wykorzystuje tzw. cookie serwisowe przede wszystkim w
					celu dostarczania Użytkownikowi usług świadczonych drogą elektroniczną oraz poprawy jakości tych usług. W
					związku z tym Administrator oraz inne podmioty świadczące na jego rzecz usługi analityczne i statystyczne
					korzystają z plików cookies, przechowując informacje lub uzyskując dostęp do informacji już przechowywanych w
					telekomunikacyjnym urządzeniu końcowym Użytkownika (komputer, telefon) 4.3 Pliki cookies wykorzystywane w tym
					celu obejmują: a) pliki cookies z danymi wprowadzanymi przez Użytkownika (identyfikator sesji) na czas trwania
					sesji (ang. user input cookies); b) uwierzytelniające pliki cookies wykorzystywane do usług wymagających
					uwierzytelniania na czas trwania sesji (ang. authentication cookies); c) pliki cookies służące do zapewnienia
					bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania (ang. user centric
					security cookies); d) sesyjne pliki cookies odtwarzaczy multimedialnych (np. pliki cookies odtwarzacza flash),
					na czas trwania sesji (ang. multimedia player session cookies); e) trwałe pliki cookies służące do
					personalizacji interfejsu Użytkownika na czas trwania sesji lub nieco dłużej (ang. user interface
					customization cookies). 4.4 Serwis internetowy nie zbiera w sposób automatyczny żadnych informacji, z
					wyjątkiem zawartych w plikach cookies. Należą do nich: adres IP, nazwa domeny, typ przeglądarki, rodzaj
					systemu operacyjnego oraz dane o ścieżce nawigacji Użytkownika i o czasie jego pozostawania na określonych
					podstronach. 4.5 Szczegółowe informacje dotyczące sposobów obsługi plików cookies znajdują się w ustawieniach
					przeglądarki internetowej. Za pomocą tych ustawień możliwe jest określenie zakresu zgody Użytkownika na
					umieszczanie tego typu plików w jego przeglądarce. Ograniczenie stosowania lub uniemożliwienie wykorzystania
					plików cookies może jednak wpłynąć na niektóre z funkcjonalności serwisu internetowego. 5. Okres przetwarzania
					Danych osobowych 5.1. Okres przetwarzania danych przez Administratora zależy od rodzaju świadczonej usługi i
					celu przetwarzania. Co do zasady dane przetwarzane są przez czas świadczenia usługi, do czasu wycofania
					wyrażonej zgody lub zgłoszenia skutecznego sprzeciwu względem przetwarzania danych w przypadkach, gdy podstawą
					prawną przetwarzania danych jest prawnie uzasadniony interes Administratora. 5.2. Okres przetwarzania danych
					może być przedłużony w przypadku, gdy przetwarzanie jest niezbędne do ustalenia i dochodzenia ewentualnych
					roszczeń lub obrony przed roszczeniami, a po tym czasie jedynie w przypadku i w zakresie, w jakim będą wymagać
					tego przepisy prawa. Po upływie okresu przetwarzania dane są nieodwracalnie usuwane lub anonimizowane. 6.
					Uprawnienia Użytkownika 6.1. Użytkownikowi przysługuje prawo dostępu do treści danych oraz żądania ich
					sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo wniesienia skargi
					do organu nadzorczego zajmującego się ochroną Danych osobowych. 6.2. Użytkownikowi przysługuje również prawo
					wniesienia sprzeciwu względem przetwarzania danych, które odbywa się w oparciu o prawnie uzasadniony interes
					Administratora. 6.3. W zakresie, w jakim dane Użytkownika przetwarzane są na podstawie zgody, można tę zgodę
					wycofać w dowolnym momencie, kontaktując się z Administratorem poprzez e-mail: kontakt@prawnik-dancewicz.pl
					lub telefonicznie: 790-534-638 7. Odbiorcy Danych osobowych 7.1. W związku z realizacją usług Dane osobowe
					będą ujawniane zewnętrznym podmiotom, w tym w szczególności dostawcom usług IT pozwalającym na prawidłowe
					korzystanie z Serwisu. 7.2. Administrator zastrzega sobie prawo ujawnienia wybranych informacji dotyczących
					Użytkownika właściwym organom bądź osobom trzecim, które zgłoszą żądanie udzielenia takich informacji,
					opierając się na odpowiedniej podstawie prawnej oraz zgodnie z przepisami obowiązującego prawa. 8.
					Przekazywanie Danych osobowych poza EOG 8.1. Poziom ochrony Danych osobowych poza Europejskim Obszarem
					Gospodarczym (EOG) różni się od tego zapewnianego przez prawo europejskie. Z tego powodu Administrator
					przekazuje Dane osobowe poza EOG tylko wtedy, gdy jest to konieczne, i z zapewnieniem odpowiedniego stopnia
					ochrony, przede wszystkim poprzez: 8.1.1. współpracę z podmiotami przetwarzającymi Dane osobowe w państwach, w
					odniesieniu do których została wydana stosowna decyzja Komisji Europejskiej dotycząca stwierdzenia zapewnienia
					odpowiedniego stopnia ochrony Danych osobowych; 8.1.2. stosowanie standardowych klauzul umownych wydanych
					przez Komisję Europejską; 8.1.3. stosowanie wiążących reguł korporacyjnych zatwierdzonych przez właściwy organ
					nadzorczy. 8.2. Administrator zawsze informuje o zamiarze przekazania Danych osobowych poza EOG na etapie ich
					zbierania. 9. Bezpieczeństwo Danych osobowych 9.1. Administrator na bieżąco prowadzi analizę ryzyka w celu
					zapewnienia, że Dane osobowe przetwarzane są przez niego w sposób bezpieczny – zapewniający przede wszystkim,
					że dostęp do danych mają jedynie osoby upoważnione i jedynie w zakresie, w jakim jest to niezbędne ze względu
					na wykonywane przez nie zadania. Administrator dba o to, by wszystkie operacje na Danych osobowych były
					rejestrowane i dokonywane jedynie przez uprawnionych pracowników i współpracowników. 9.2. Administrator
					podejmuje wszelkie niezbędne działania, by także jego podwykonawcy i inne podmioty współpracujące dawały
					gwarancję stosowania odpowiednich środków bezpieczeństwa w każdym przypadku, gdy przetwarzają Dane osobowe na
					zlecenie Administratora. 10. Dane kontakowe 10.1 Kontakt z Administratorem jest możliwy poprzez e-mail:
					kontakt@prawnik-dancewicz.pl, telefonicznie: 790-534-638, lub pod adresem: Kancelaria Radcy Prawnego Wojciech
					Dancewicz, ul. Szpitalna 8/409, 44-190 Knurów 11. Zmiany Polityki Prywatności 11.1.Polityka jest na bieżąco
					weryfikowana i w razie potrzeby aktualizowana. 11.2.Aktualna wersja Polityki została przyjęta i obowiązuje od
					6.07.2023 r.
				</div>
			</motion.div>
		</>
	)
}

export default FooterPrivacy
