import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import WorksItems from './WorksItems'
import WorksMenu from './WorksMenu'
import WorksDetail from './WorksDetail'
import { websites, applications } from './WorksData'

import classes from './Works.module.css'
import { AnimatePresence } from 'framer-motion'

const Works = () => {
	const [array, setArray] = useState()
	const [show, setShow] = useState(true)
	const [currentItemDetail, setCurrentItemDetail] = useState()

	const activeArray = elo => {
		setArray(elo)
	}

	const showHandler = yo => {
		setShow(yo)
	}
	const location = useLocation()
	useEffect(() => {
		const szukaneId = location.pathname

		const znalezioneWebsites = websites.filter(website => website.path === szukaneId)

		const znalezioneApplications = applications.filter(application => application.path === szukaneId)

		let path

		if (znalezioneApplications.length === 1) {
			setCurrentItemDetail(znalezioneApplications[0])
			path = `/${znalezioneApplications[0].id}`
		} else if (znalezioneWebsites.length === 1) {
			setCurrentItemDetail(znalezioneWebsites[0])
			path = `/${znalezioneWebsites[0].id}`
		} else {
		}

		if (location.pathname === '/') {
			setShow(true)
		} else if (location.pathname === path) {
			setShow(false)
			setTimeout(() => {
				scroller.scrollTo('works', { smooth: true, offset: 50 })
			}, 500)
		} else {
			setShow(false)
		}
	}, [location.pathname])

	const currentItem = item => {
		setCurrentItemDetail(item)
	}

	return (
		<section className={`${classes.portfolio} works`}>
			<WorksMenu activeArray={activeArray} show={showHandler} />
			{show ? (
				<WorksItems array={array} show={showHandler} currentItem={currentItem} />
			) : (
				<WorksDetail currentArray={currentItemDetail} show={showHandler} />
			)}
		</section>
	)
}

export default Works
