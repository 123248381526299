import { Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import classes from './WorksDetail.module.css'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { motion } from 'framer-motion'

const WorksDetail = ({ show, currentArray }) => {
	const [image, setImage] = useState(currentArray.imageDesktop)
	const [buttonActive, setButtonActive] = useState('desktops')

	const buttonGalleryHandler = item => {
		if (item === 'desktops') {
			setImage(currentArray.imageDesktop)
			setButtonActive('desktops')
		} else if (item === 'tablets') {
			setImage(currentArray.imageTablets)
			setButtonActive('tablets')
		} else if (item === 'phones') {
			setImage(currentArray.imagePhones)
			setButtonActive('phones')
		}
	}

	return (
		<div className={classes['worksdetail__wrapper']}>
			<div className={classes['worksdetail__image']}>
				<img src={image} alt="elo" />
			</div>
			<div className={classes['worksdetail__content']}>
				<div className={classes['worksdetail__text']}>
					<div className={classes['worksdetail__title']}>
						<h3>{currentArray.name}</h3>
						<h4>
							Type: {currentArray.type}
							<br />
							Used: {currentArray.used}
						</h4>
					</div>
					<p>{currentArray.descriptionTop}</p>
					<p>{currentArray.descriptionBottom}</p>
					<Link to="/" onClick={(() => show(true), () => scroller.scrollTo('works', { smooth: true, offset: 50 }))}>
						<button>back to {currentArray.type}s</button>
					</Link>
				</div>

				<div className={classes['worksdetail__nav']}>
					<ul>
						{currentArray.galleryButtons.map((item, index) => (
							<motion.li
								className={item === buttonActive ? classes['worksdetail__nav--active'] : ''}
								onClick={() => buttonGalleryHandler(item)}
								key={index}>
								{item}
							</motion.li>
						))}
					</ul>
				</div>
			</div>
		</div>
	)
}

export default WorksDetail
