export const codeSkills = [
	{
		id: 'c1',
		title: 'Front-end',
		subtitle: ['html5', 'css3', 'sass', 'rwd'],
		description: [
			'Semantics, form creation, ensuring accessibility, structuring documents, handling multimedia, code validation, and implementing responsive design.',
			'Syntax, selectors, box model, floats, Flexbox, responsiveness, animations, pseudo-classes, typography management, CSS frameworks.',
			'SASS syntax, variables, nesting, mixins, modularity, inheritance, file importing.',
			'Media queries, creating flexible layouts, knowledge of CSS flexbox and grid, implementing the "Mobile-First" approach, managing typography, testing on various devices, ensuring accessibility.',
		],
	},
	{
		id: 'c2',
		title: 'Frameworks',
		subtitle: ['React', 'Bootstrap'],
		description: [
			'React syntax, functional components, handle routing using libraries like React Router, i18next, Framer Motion, manage state, make network requests, and communicate with external APIs, implementing conditional rendering, handling forms, and effectively optimizing performance',
			'Syntax and functionality, the ability to create responsive websites using Bootstraps grid system, customization of components and styles to meet individual project needs, as well as integrating Bootstrap with various frameworks.',
		],
	},
	{
		id: 'c3',
		title: 'Programming',
		subtitle: ['ECMAScript', 'JavaScript', 'TypeScript'],
		description: [
			'Understanding basic syntax, using arrow functions, applying "let" and "const" variables, destructuring syntax, import ES6 modules, utilizing new array methods such as "map," "filter," and "reduce.',
			'Basic syntax, DOM manipulation, event handling, functions, arrays and objects.',
			'Declare variables with specific data types, define interfaces to specify object structures.',
		],
	},
	{
		id: 'c4',
		title: 'Networking',
		subtitle: ['http'],
		description: ['Basic HTTP methods, understand status codes.'],
	},
	{
		id: 'c5',
		title: 'Hosting',
		subtitle: ['firebase'],
		description: ['Configure and use Firebase, including using Firestore for real-time data storage.'],
	},
	{
		id: 'c6',
		title: 'Versioning',
		subtitle: ['git & github'],
		description: [
			'Initialize repositories, clone existing ones, add and commit changes, push and pull changes from GitHub, create and review commit history, and manage .gitignore files in Git.',
		],
	},
	{
		id: 'c7',
		title: 'Graphic',
		subtitle: ['adobe photoshop cs6', 'adobe illustrator cs6', 'gimp'],
		description: [
			'Basic editing and creation of graphics for websites development.',
			'Basic editing and creation of vector graphics for websites development.',
			'Basic editing and creation of graphics for websites development.',
		],
	},
	{
		id: 'c8',
		title: 'CMS',
		subtitle: ['wordpress'],
		description: ['Install, configure, create, manage themes and plugins, create and edit content, handle media.'],
	},
	{
		id: 'c9',
		title: 'Other',
		subtitle: ['npm'],
		description: ['Install and initialize npm projects, manage packages by installing, updating, and deleting them.'],
	},
]

export const experienceSkills = [
	{
		id: 'x1',
		title: 'Radca Prawny - Wojciech Dancewicz',
		subtitle: ['www.prawnik-dancewicz.pl'],
		start: '2023',
		end: '2023',
		description: [''],
	},
	{
		id: 'x2',
		title: 'Lamina Monika Paprocka',
		subtitle: ['www.lamina-gliwice.pl'],
		start: '2023',
		end: '2023',
		description: [''],
	},
	{
		id: 'x3',
		title: 'Kancelaria Adwokacka - Adwokat Jarosław Cmok',
		subtitle: ['www.jc-kancelaria.pl'],
		start: '2023',
		end: '2023',
		description: [''],
	},
	{
		id: 'x4',
		title: 'Nicechem Sp. z o. o.',
		subtitle: ['www.nicechem.pl'],
		start: '2023',
		end: '2023',
		description: [''],
	},
	{
		id: 'x5',
		title: 'mgrek.com',
		subtitle: ['www.mgrek.com'],
		start: '2023',
		end: '2023',
		description: [''],
	},
]
