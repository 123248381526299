import { useState, useEffect } from 'react'
import HeaderNavbarToggler from './HeaderToggler/HeaderToggler'
import HeaderLogo from './HeaderLogo/HeaderLogo'
import HeaderSocials from './HeaderSocials/HeaderSocials'
import classes from './Header.module.css'

const Header = ({ onTheme }) => {
	const [theme, setTheme] = useState('')

	let localTheme = localStorage.getItem('theme')

	const changeTheme = theme => {
		setTheme(theme)
		localStorage.setItem('theme', theme)
	}

	useEffect(() => {
		const setDarkTheme = () => {
			localStorage.setItem('theme', 'dark')
			document.querySelector('body').classList.remove('light')
			document.querySelector('body').classList.add('dark')
			setTheme('dark')
		}

		const setLightTheme = () => {
			localStorage.setItem('theme', 'light')
			document.querySelector('body').classList.remove('dark')
			document.querySelector('body').classList.add('light')
			setTheme('light')
		}

		if (theme === 'dark' || localTheme === 'dark') {
			setDarkTheme()
		} else if (theme === 'light' || localTheme === 'light') {
			setLightTheme()
		} else {
			const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
			if (prefersDarkMode) {
				setDarkTheme()
			} else {
				setLightTheme()
			}
		}
	}, [localTheme, theme])

	useEffect(() => {
		onTheme(theme)
	})

	return (
		<header className={classes.header}>
			<HeaderNavbarToggler />
			<HeaderLogo onTheme={theme} />
			<HeaderSocials onTheme={theme} onChangeTheme={changeTheme} />
		</header>
	)
}

export default Header
