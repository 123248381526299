import { useState } from 'react'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import './App.css'
import Header from './components/Header/Header'
import Home from './components/Home/Home'
import About from './components/About/About'
import Works from './components/Works/Works'
import Container from './components/UI/Container'
import Charity from './components/Charity/Charity'
import Clients from './components/Clients/Clients'
import Footer from './components/Footer/Footer'
import WorksItems from './components/Works/WorksItems'
import WorksDetail from './components/Works/WorksDetail'

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.use(LanguageDetector)
	.use(HttpApi)
	.init({
		// the translations
		// (tip move them in a JSON file and import them,
		// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

		supportedLngs: ['en', 'pl'],
		fallbackLng: 'en',
		detection: {
			order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
		},
		backend: {
			loadPath: '/assets/locales/{{lng}}/translation.json',
		},
	})

const router = createBrowserRouter([
	{
		path: '/',
		element: <Works />,
		children: [
			{ path: '/', element: <WorksItems /> },
			{ path: '/:worksId', element: <WorksDetail /> },
		],
	},
])

function App() {
	const [theme, setTheme] = useState()

	const themeHandler = theme => {
		setTheme(theme)
	}

	return (
		<>
			<Header onTheme={themeHandler} />
			<Container>
				<Home />
				<About />
				<RouterProvider router={router}>
					<Works />
				</RouterProvider>
				<Charity />
				<Clients onTheme={theme} />
				<Footer />
			</Container>
		</>
	)
}

export default App
