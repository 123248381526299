import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../UI/Button'
import classes from './AboutContent.module.css'

const AboutContent = ({ onCurrentSlide, sliderGallery }) => {
	const { t } = useTranslation()
	const [currentSlide, setCurrentSlide] = useState(0)

	const nextSlideHandler = () => {
		setCurrentSlide(currentSlide === sliderGallery.length - 1 ? 0 : currentSlide + 1)
	}

	const prevSlideHandler = () => {
		setCurrentSlide(currentSlide === 0 ? sliderGallery.length - 1 : currentSlide - 1)
	}

	useEffect(() => {
		onCurrentSlide(currentSlide)
	}, [onCurrentSlide, currentSlide])

	return (
		<div className={classes['about__content--wrapper']}>
			<div className={classes.about__content}>
				<div className={classes['about__content--title']}>
					<h3>Mateusz Grek</h3>
					<h4>Freelance Front-End Developer</h4>
				</div>
				<div className={classes['about__content--text']}>
					<p>{t('about_text')}</p>
					<div className={classes['about__content--learn']}>
						<h3>
							{t('about_currently_learning')}: <span>TypeScript</span>
						</h3>
						<h3>
							{t('about_after_work')}: <span>Fishing App</span>
						</h3>
					</div>
					<Button type={'submit'}>
						<a
							href="/assets/files/mateusz-grek-junior-front-end-developer.pdf"
							download="mateusz-grek-junior-front-end-developer"
							target="_blank"
							rel="noreferrer">
							{t('about_download')}
						</a>

						<i className="lni lni-download"></i>
					</Button>
				</div>
			</div>
			<div className={classes['about__content--nav']}>
				<div className={classes['about__content--controls']}>
					<span onClick={prevSlideHandler}>
						<i className="lni lni-chevron-left"></i>
					</span>
					<span onClick={nextSlideHandler}>
						<i className="lni lni-chevron-right"></i>
					</span>
				</div>
				<div className={classes['about__content--name']}>
					<div className="about__content--item fade about__content--active">
						<p>{sliderGallery.map(item => item.id === currentSlide && item.description)}.</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AboutContent
