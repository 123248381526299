import { useState } from 'react'
import classes from './AboutSkills.module.css'
import AboutSkillsButtons from './AboutSkillsButtons'
import AboutSkillsContent from './AboutSkillsContent'

const AboutSkills = () => {
	const [activeButtons, setActiveButtons] = useState()

	const selectedButtonsHandler = selectedButtons => {
		setActiveButtons(selectedButtons)
	}

	return (
		<section className={classes.skills}>
			<AboutSkillsButtons onActiveButtons={selectedButtonsHandler} />
			<AboutSkillsContent onActiveButtons={activeButtons} />
		</section>
	)
}

export default AboutSkills
