import { useEffect, useState } from 'react'
import AboutSkillsType from './AboutSkillsType'
import { codeSkills, experienceSkills } from './AboutSkillsData'
import classes from './AboutSkillsContent.module.css'

const AboutSkillsContent = ({ onActiveButtons }) => {
	const [skillData, setSkillData] = useState(onActiveButtons)

	useEffect(() => {
		if (onActiveButtons !== undefined && onActiveButtons.showCode === true) {
			setSkillData(codeSkills)
		}

		if (onActiveButtons !== undefined && onActiveButtons.showExperience === true) {
			setSkillData(experienceSkills)
		}
	}, [onActiveButtons])

	return (
		<div className={classes.skills__content}>
			{skillData !== undefined && <AboutSkillsType content={skillData} onActiveButtons={onActiveButtons} />}
		</div>
	)
}

export default AboutSkillsContent
