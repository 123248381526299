import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classes from './AboutSkillsButtons.module.css'

const AboutSkillsButtons = ({ onActiveButtons }) => {
	const { t } = useTranslation()
	const [showCode, setShowCode] = useState(true)
	const [showExperience, setShowExperience] = useState(false)

	const codeHandler = () => {
		setShowCode(true)

		setShowExperience(false)
	}

	const experienceHandler = () => {
		setShowExperience(true)
		setShowCode(false)
	}

	useEffect(() => {
		onActiveButtons({ showCode, showExperience })
	}, [showCode, showExperience])

	return (
		<div className={classes.skills__buttons}>
			<ul>
				<li className={showCode ? classes['skills__buttons--active'] : ''} onClick={codeHandler}>
					<div>
						<span>{t('programming_skills')}</span>
					</div>
				</li>
				<li className={showExperience ? classes['skills__buttons--active'] : ''} onClick={experienceHandler}>
					<div>
						<span>{t('experience')}</span>
					</div>
				</li>
			</ul>
		</div>
	)
}

export default AboutSkillsButtons
