import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import Title from '../UI/Title'
import classes from './WorksMenu.module.css'
import { websites, applications } from './WorksData'
import { motion } from 'framer-motion'

const WorksMenu = props => {
	const { t } = useTranslation()
	const [activeButton, setActiveButton] = useState(websites)

	let activeArray = activeButton

	const setButtonHandler = activeBtn => {
		setActiveButton(activeBtn)
	}

	useEffect(() => {
		props.activeArray(activeArray)
	}, [props, activeArray])
	return (
		<div className={classes.portfolio__top}>
			<Title border={false}>{t('last_works')}</Title>
			<div className={classes.portfolio__filter}>
				<ul>
					<Link>
						<li
							onClick={() => setButtonHandler(websites)}
							className={activeButton === websites ? classes['portfolio__filter--active'] : ''}>
							{t('websites')}
							{activeButton === websites ? (
								<motion.div
									className={classes['portfolio__filter--border']}
									layoutId={classes['portfolio__filter--border']}
								/>
							) : null}
						</li>
					</Link>
					<Link>
						<li
							onClick={() => setButtonHandler(applications)}
							className={activeButton === applications ? classes['portfolio__filter--active'] : ''}>
							{t('applications')}
							{activeButton === applications ? (
								<motion.div
									className={classes['portfolio__filter--border']}
									layoutId={classes['portfolio__filter--border']}
								/>
							) : null}
						</li>
					</Link>
				</ul>
			</div>
		</div>
	)
}

export default WorksMenu
