import { useTranslation } from 'react-i18next'
import { Link } from 'react-scroll'
import classes from './HeaderNavbar.module.css'

const HeaderNavbar = ({ isOpenMenu, onCloseMenu }) => {
	const { t } = useTranslation()
	const closeMenuHandler = () => {
		onCloseMenu(false)
	}

	return (
		<div className={`${classes.header__menu} ${isOpenMenu ? classes['header__menu--active'] : ''}`}>
			{isOpenMenu && (
				<div className={`${classes['header__menu--close']}`} onClick={closeMenuHandler}>
					<i className={`lni lni-close`}></i>
				</div>
			)}

			<nav>
				<ul>
					<li>
						<Link to="home" spy={true} smooth={true} offset={0} duration={500} onClick={closeMenuHandler}>
							<span>{t('header_home')}</span>
						</Link>
					</li>
					<li>
						<Link to="about" spy={true} smooth={true} offset={-20} duration={500} onClick={closeMenuHandler}>
							<span>{t('header_about')}</span>
						</Link>
					</li>
					<li>
						<Link to="works" spy={true} smooth={true} offset={50} duration={500} onClick={closeMenuHandler}>
							<span>{t('header_works')}</span>
						</Link>
					</li>
					<li>
						<Link to="charity" spy={true} smooth={true} offset={50} duration={500} onClick={closeMenuHandler}>
							<span>{t('header_charity')}</span>
						</Link>
					</li>
					<li>
						<Link to="contact" spy={true} smooth={true} offset={50} duration={500} onClick={closeMenuHandler}>
							<span>{t('header_contact')}</span>
						</Link>
					</li>
				</ul>
			</nav>
			<div className={classes['header__menu--burger']}>
				<p>{t('header_menu_text')}</p>
				<p>
					<a href="https://github.com/mateusz-grek" target="_blank" rel="noopener noreferrer">
						<i className="lni lni-github"></i>
					</a>
				</p>
				<p>
					<a href="https://www.linkedin.com/in/mateusz-grek-68a634223/" target="_blank" rel="noopener noreferrer">
						<i className="lni lni-linkedin"></i>
					</a>
				</p>
				<p>
					<a href="https://www.facebook.com/profile.php?id=100068921553925" target="_blank" rel="noopener noreferrer">
						<i className="lni lni-facebook"></i>
					</a>
				</p>
				<p>
					<a href="https://www.instagram.com/b0gusz/" target="_blank" rel="noopener noreferrer">
						<i className="lni lni-instagram"></i>
					</a>
				</p>
			</div>
		</div>
	)
}

export default HeaderNavbar
