import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Title from '../UI/Title'
import AboutSlider from './AboutSlider/AboutSlider'
import AboutContent from './AboutContent/AboutContent'
import AboutSkills from './AboutSkills/AboutSkills'

import fishing from './fishing.webp'
import rollerblading from './rollerblade.webp'

import classes from './About.module.css'

const images = [
	{
		id: 0,
		image: rollerblading,
		description: 'Rollerblading',
	},
	{
		id: 1,
		image: fishing,
		description: 'Fishing',
	},
]

const About = () => {
	const { t } = useTranslation()
	const [currentSlide, setCurrentSlide] = useState(0)

	const currentSlideHandler = currentSlide => {
		setCurrentSlide(currentSlide)
	}

	return (
		<section className="about">
			<Title border={true}>{t('about_me')}</Title>
			<div className={classes.about__wrapper}>
				<AboutSlider onCurrentSlide={currentSlide} sliderGallery={images} />
				<AboutContent onCurrentSlide={currentSlideHandler} sliderGallery={images} />
			</div>
			<AboutSkills />
		</section>
	)
}

export default About
