import { Link } from 'react-scroll'
import logoDark from './logo-dark.webp'
import logoLight from './logo-light.webp'
import classes from './HeaderLogo.module.css'

const HeaderLogo = ({ onTheme }) => {
	return (
		<Link to="home" spy={true} smooth={true} offset={0} duration={500} className={classes.header__logo}>
			<img src={onTheme === 'dark' ? logoLight : logoDark} alt="mg logo" />
		</Link>
	)
}

export default HeaderLogo
