import { useState, useEffect } from 'react'
import HeaderNavbar from '../HeaderNavbar/HeaderNavbar'
import classes from './HeaderToggler.module.css'

const HeaderToggle = () => {
	const [isOpenMenu, setIsOpenMenu] = useState(false)

	const togglerHandler = () => {
		setIsOpenMenu(!isOpenMenu)
	}

	const closeMenuHandler = close => {
		setIsOpenMenu(close)
	}

	useEffect(() => {
		if (isOpenMenu) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'unset'
		}
	}, [isOpenMenu])

	return (
		<>
			<div className={`${classes.header__toggler} ${classes['header__toggler--menu']}`} onClick={togglerHandler}>
				<i className={`lni lni-menu`}></i>
			</div>
			<HeaderNavbar isOpenMenu={isOpenMenu} onCloseMenu={closeMenuHandler} />
			{isOpenMenu && <div className={classes.header__backdrop} onClick={togglerHandler} />}
		</>
	)
}

export default HeaderToggle
