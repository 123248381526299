import { useState } from 'react'
import classes from './HeaderSocials.module.css'
import i18next from 'i18next'

const HeaderSocials = ({ onTheme, onChangeTheme }) => {
	const [language, setLanguage] = useState('en')

	const changeThemeHandler = () => {
		if (onTheme === 'dark') {
			onChangeTheme('light')
		} else {
			onChangeTheme('dark')
		}
	}

	const changeLanguageHandler = () => {
		if (language === 'en') {
			setLanguage('pl')
			i18next.changeLanguage('pl')
		} else {
			setLanguage('en')
			i18next.changeLanguage('en')
		}
	}

	return (
		<div className={classes.header__socials}>
			<ul>
				<li onClick={changeLanguageHandler}>
					<div className={classes['header__beta--border']}></div>
					<div className={classes.header__beta}>
						<span>beta</span>
					</div>
					<span>{language === 'en' ? 'pl' : 'en'}</span>
				</li>
				<li className="darkmode" onClick={changeThemeHandler}>
					<span>
						<i className={onTheme === 'dark' ? 'lni lni-invention' : 'lni lni-night'}></i>
					</span>
				</li>
				<li>
					<a href="https://github.com/mateusz-grek" target="_blank" rel="noopener noreferrer">
						<i className="lni lni-github"></i>
					</a>
				</li>
				<li>
					<a href="https://www.linkedin.com/in/mateusz-grek-68a634223/" target="_blank" rel="noopener noreferrer">
						<i className="lni lni-linkedin"></i>
					</a>
				</li>
				<li>
					<a href="https://www.facebook.com/profile.php?id=100068921553925" target="_blank" rel="noopener noreferrer">
						<i className="lni lni-facebook"></i>
					</a>
				</li>
				<li>
					<a href="https://www.instagram.com/b0gusz/" target="_blank" rel="noopener noreferrer">
						<i className="lni lni-instagram"></i>
					</a>
				</li>
			</ul>
		</div>
	)
}

export default HeaderSocials
